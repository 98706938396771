import React from "react";
import Navbar from "./components/Navbar";
import { Container } from "@mui/material";
import Footer from "./components/Footer";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import About from "./components/About";
import Home from "./components/Home";
import Blogs from "./components/Blogs";
import BlogDetail from "./components/BlogDetail";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Terms from "./components/Terms";
import ContactUs from "./components/ContactUs";
import RouteOpt from "./components/RouteOpt";

function App() {
  return (
    <Router>
      <div
        style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
      >
        <Navbar />
        <Container style={{ flex: 1 }}>
          <Routes>
            <Route path="/about" exact element={<About />} />
            <Route path="/blogs" exact element={<Blogs />} />
            <Route path="/" exact element={<Home />} />
            <Route path="/blog/:id" exact element={<BlogDetail />} />
            <Route path="/terms-of-service" exact element={<Terms />} />
            <Route path="/privacy-policy" exact element={<PrivacyPolicy />} />
            <Route path="/contact-us" exact element={<ContactUs />} />
            <Route path="/route-opt" exact element={<RouteOpt />} />
          </Routes>
        </Container>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
