import React, { useState, useRef, useCallback, useEffect } from "react";
import {
  Grid,
  Box,
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Paper,
  Autocomplete,
  TextField,
  Divider,
  CircularProgress,
} from "@mui/material";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  DirectionsRenderer,
} from "@react-google-maps/api";
import DeleteIcon from "@mui/icons-material/Delete";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import RouteIcon from "@mui/icons-material/Route";
import OptimizeIcon from "@mui/icons-material/TrendingUp";
import DownloadIcon from "@mui/icons-material/Download";
import ChatIcon from "@mui/icons-material/Chat";
import parse from "autosuggest-highlight/parse";
import throttle from "lodash/throttle";
import Papa from "papaparse";
import axios from "axios";

const GOOGLE_MAPS_API_KEY = "AIzaSyAgevzpNT5k789mPwefGLecGIWT-itAUUY";
const API_BASE_URL = "http://localhost:8000";

const RouteOpt = () => {
  const [mapCenter, setMapCenter] = useState({ lat: 28.6139, lng: 77.2088 });
  const [stops, setStops] = useState([]);
  const [startPoint, setStartPoint] = useState(null);
  const [endPoint, setEndPoint] = useState(null);
  const [directions, setDirections] = useState(null);
  const [optimizedPlan, setOptimizedPlan] = useState(null);
  const [isChatbotOpen, setIsChatbotOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const mapRef = useRef();
  const [libraries] = useState(["places"]);
  const [calculatedRouteInfo, setCalculatedRouteInfo] = useState(null); // New state for calculated route info
  const [optimizedRouteInfo, setOptimizedRouteInfo] = useState(null); // New state for optimized route info

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries,
  });

  const handlePlaceSelect = (place, type) => {
    if (place && place.place_id) {
      const service = new window.google.maps.places.PlacesService(
        mapRef.current
      );
      service.getDetails(
        {
          placeId: place.place_id,
          fields: ["geometry", "formatted_address"],
        },
        (result, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            const newPlace = {
              address: result.formatted_address,
              lat: result.geometry.location.toJSON().lat,
              lng: result.geometry.location.toJSON().lng,
              location: result.geometry.location.toJSON(),
            };

            if (type === "start") {
              setStartPoint(newPlace);
            } else if (type === "end") {
              setEndPoint(newPlace);
            } else {
              setStops((prev) => [...prev, newPlace]);
            }

            setMapCenter(newPlace.location);
          }
        }
      );
    }
  };

  const handleDeleteStop = (index) => {
    setStops((prev) => prev.filter((_, i) => i !== index));
  };

  const calculateRoute = useCallback(
    async (routeStops = stops) => {
      if (
        !startPoint?.location ||
        !endPoint?.location ||
        routeStops.length === 0
      )
        return;

      try {
        const response = await axios.post(`${API_BASE_URL}/calculate-route`, {
          start_point: startPoint,
          end_point: endPoint,
          stops: routeStops,
        });

        const { directions, totalDistance, totalDuration } = response.data;

        setDirections(directions);
        console.log(directions);
        // Set calculated route info
        setCalculatedRouteInfo(
          `Total distance: ${totalDistance.toFixed(
            2
          )} km, Estimated time: ${totalDuration.toFixed(2)} minutes`
        );
      } catch (error) {
        console.error("Error fetching directions:", error);
        setCalculatedRouteInfo("Failed to calculate route. Please try again."); // Set error message
      }
    },
    [startPoint, endPoint, stops]
  );

  const optimizeRoute = useCallback(async () => {
    if (!startPoint?.location || !endPoint?.location || stops.length === 0) {
      alert(
        "Please set start point, end point, and at least one stop before optimizing the route."
      );
      return;
    }

    if (stops.length < 2) {
      alert("Please add at least two stops before optimizing the route.");
      return;
    }

    setIsLoading(true);

    try {
      const response = await axios.post(`${API_BASE_URL}/optimize-route`, {
        start_point: startPoint,
        end_point: endPoint,
        stops: stops,
      });
      const { route } = response.data;

      // Update the stops state and recalculate the route
      const newStops = route.slice(1, -1); // Exclude start and end points
      setStops(newStops);
      const response1 = await axios.post(`${API_BASE_URL}/calculate-route`, {
        start_point: startPoint,
        end_point: endPoint,
        stops: newStops,
      });
      const { totalDistance, totalDuration } = response1.data;
      setOptimizedPlan(route);

      setOptimizedRouteInfo(
        `Total distance: ${totalDistance.toFixed(
          2
        )} km, Total duration: ${totalDuration.toFixed(2)} minutes`
      );
    } catch (error) {
      console.error("Error in route optimization:", error);
      setOptimizedRouteInfo("Failed to optimize the route. Please try again."); // Set error message
    } finally {
      setIsLoading(false);
    }
  }, [startPoint, endPoint, stops, calculateRoute]);

  const downloadOptimizedPlan = () => {
    if (!optimizedPlan) {
      alert("Please optimize the route first.");
      return;
    }

    const planText = optimizedPlan
      .map((location, index) => {
        if (index === 0) return `Start: ${location.address}`;
        if (index === optimizedPlan.length - 1)
          return `End: ${location.address}`;
        return `Stop ${index}: ${location.address}`;
      })
      .join("\n");

    const blob = new Blob([planText], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "optimized_route_plan.txt";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileType = file.name.split(".").pop();
      if (fileType === "csv") {
        Papa.parse(file, {
          complete: (results) => {
            const newStops = results.data
              .filter((row) => row.length >= 2 && row[0] && row[1])
              .map((row) => ({
                address: `${row[2] || "Unknown"} (${row[0]}, ${row[1]})`,
                location: { lat: parseFloat(row[0]), lng: parseFloat(row[1]) },
              }));
            setStops((prev) => [...prev, ...newStops]);
          },
        });
      } else if (fileType === "txt") {
        const reader = new FileReader();
        reader.onload = (e) => {
          const text = e.target.result;
          const addresses = text
            .split("\n")
            .filter((line) => line.trim() !== "");
          // Note: You'll need to implement geocoding for these addresses
          // This is left as an exercise as it requires additional API calls
        };
        reader.readAsText(file);
      } else {
        alert("Unsupported file type. Please upload a .csv or .txt file.");
      }
    }
  };

  const LocationAutocomplete = ({ onSelect, placeholder, type, value }) => {
    const [inputValue, setInputValue] = useState("");
    const [options, setOptions] = useState([]);
    const loaded = useRef(false);

    useEffect(() => {
      if (typeof window !== "undefined" && !loaded.current && window.google) {
        loaded.current = true;
      }
    }, []);

    const fetch = useCallback(
      throttle((input, callback) => {
        const service = new window.google.maps.places.AutocompleteService();
        service.getPlacePredictions({ input }, callback);
      }, 200),
      []
    );

    useEffect(() => {
      let active = true;

      if (!loaded.current) {
        return undefined;
      }

      if (inputValue === "") {
        setOptions(value ? [value] : []);
        return undefined;
      }

      fetch(inputValue, (results) => {
        if (active) {
          let newOptions = [];

          if (value) {
            newOptions = [value];
          }

          if (results) {
            newOptions = [...newOptions, ...results];
          }

          setOptions(newOptions);
        }
      });

      return () => {
        active = false;
      };
    }, [value, inputValue, fetch]);

    return (
      <Autocomplete
        getOptionLabel={(option) =>
          typeof option === "string"
            ? option
            : option.description || option.address
        }
        filterOptions={(x) => x}
        options={options}
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={value}
        noOptionsText="No locations"
        onChange={(event, newValue) => {
          setOptions(newValue ? [newValue, ...options] : options);
          onSelect(newValue, type);
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderInput={(params) => (
          <TextField {...params} label={placeholder} fullWidth />
        )}
        renderOption={(props, option) => {
          const matches =
            option.structured_formatting?.main_text_matched_substrings || [];

          const parts = parse(
            option.structured_formatting?.main_text,
            matches.map((match) => [match.offset, match.offset + match.length])
          );

          return (
            <li {...props}>
              <Grid container alignItems="center">
                <Grid item sx={{ display: "flex", width: 44 }}>
                  <LocationOnIcon sx={{ color: "text.secondary" }} />
                </Grid>
                <Grid
                  item
                  sx={{ width: "calc(100% - 44px)", wordWrap: "break-word" }}
                >
                  {parts.map((part, index) => (
                    <Box
                      key={index}
                      component="span"
                      sx={{ fontWeight: part.highlight ? "bold" : "regular" }}
                    >
                      {part.text}
                    </Box>
                  ))}
                  <Typography variant="body2" color="text.secondary">
                    {option.structured_formatting?.secondary_text}
                  </Typography>
                </Grid>
              </Grid>
            </li>
          );
        }}
      />
    );
  };

  const toggleChatbot = () => {
    setIsChatbotOpen(!isChatbotOpen);
  };

  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading maps</div>;

  return (
    <Box sx={{ display: "flex", height: "100vh", width: "100vw" }}>
      {/* Sidebar */}
      <Box
        sx={{
          width: "310px",
          backgroundColor: "#fff",
          display: "flex",
          flexDirection: "column",
          overflowY: "auto",
          marginRight: "20px",
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography variant="h5" gutterBottom sx={{ mb: 3 }}>
            <b>Route Optimization</b>
          </Typography>

          <Box sx={{ mb: 3 }}>
            <Typography variant="subtitle1" gutterBottom>
              Start Point
            </Typography>
            <LocationAutocomplete
              onSelect={handlePlaceSelect}
              placeholder="Enter start location"
              type="start"
              value={startPoint}
            />
          </Box>

          <Box sx={{ mb: 3 }}>
            <Typography variant="subtitle1" gutterBottom>
              End Point
            </Typography>
            <LocationAutocomplete
              onSelect={handlePlaceSelect}
              placeholder="Enter end location"
              type="end"
              value={endPoint}
            />
          </Box>

          <Divider sx={{ my: 3 }} />

          <Typography variant="subtitle1" gutterBottom>
            Stops
          </Typography>
          <Paper
            sx={{
              maxHeight: 200,
              minHeight: 150,
              overflow: "auto",
              mb: 2,
              flex: 1,
            }}
          >
            <List>
              {stops.map((stop, index) => (
                <ListItem
                  key={index}
                  secondaryAction={
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => handleDeleteStop(index)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  }
                >
                  <ListItemText primary={`${index + 1}. ${stop.address}`} />
                </ListItem>
              ))}
            </List>
          </Paper>
          <LocationAutocomplete
            onSelect={handlePlaceSelect}
            placeholder="Add a stop"
            type="stop"
            value={null}
          />

          <Box sx={{ display: "flex", justifyContent: "left", mt: 2, mb: 3 }}>
            <Button
              variant="outlined"
              component="label"
              startIcon={<UploadFileIcon />}
              sx={{ mr: 1 }}
            >
              Upload csv
              <input
                type="file"
                hidden
                accept=".csv"
                onChange={handleFileUpload}
              />
            </Button>
            <Button
              variant="outlined"
              component="label"
              startIcon={<UploadFileIcon />}
            >
              Upload txt
              <input
                type="file"
                hidden
                accept=".txt"
                onChange={handleFileUpload}
              />
            </Button>
          </Box>

          <Box sx={{ display: "flex", flexDirection: "column", gap: 2, mt: 3 }}>
            <Button
              variant="contained"
              onClick={() => calculateRoute()}
              startIcon={<RouteIcon />}
              sx={{
                backgroundColor: "#1976d2",
                "&:hover": {
                  backgroundColor: "#1565c0",
                },
              }}
            >
              Calculate Route
            </Button>

            {calculatedRouteInfo && (
              <Box
                sx={{
                  mb: 2,
                  p: 2,
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                }}
              >
                <Typography variant="h6">Calculated Route Info</Typography>
                <Typography variant="body1" color="text.primary">
                  {calculatedRouteInfo}
                </Typography>
              </Box>
            )}
            <Button
              variant="contained"
              onClick={optimizeRoute}
              startIcon={<OptimizeIcon />}
              sx={{
                backgroundColor: "#2e7d32",
                "&:hover": {
                  backgroundColor: "#1b5e20",
                },
              }}
              disabled={isLoading}
            >
              {isLoading ? <CircularProgress size={24} /> : "Optimize Route"}
            </Button>

            {optimizedRouteInfo && (
              <Box
                sx={{
                  mb: 2,
                  p: 2,
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                }}
              >
                <Typography variant="h6">Optimized Route Info</Typography>
                <Typography variant="body1" color="text.primary">
                  {optimizedRouteInfo}
                </Typography>
              </Box>
            )}
            {optimizedPlan && (
              <Button
                variant="outlined"
                onClick={downloadOptimizedPlan}
                startIcon={<DownloadIcon />}
              >
                Download Optimized Plan
              </Button>
            )}
            <Button
              variant="outlined"
              onClick={toggleChatbot}
              startIcon={<ChatIcon />}
            >
              {isChatbotOpen ? "Close Chatbot" : "Open Chatbot"}
            </Button>
          </Box>
        </Box>
      </Box>

      {/* Map Container */}
      <Box sx={{ flex: 1, display: "flex" }}>
        <Box sx={{ width: "100%", height: "100%" }}>
          <GoogleMap
            mapContainerStyle={{ width: "100%", height: "100%" }}
            center={mapCenter}
            zoom={13}
            onLoad={(map) => {
              mapRef.current = map;
            }}
          >
            {!directions && (
              <>
                {startPoint?.location && (
                  <Marker position={startPoint.location} label="S" />
                )}
                {endPoint?.location && (
                  <Marker position={endPoint.location} label="E" />
                )}
                {stops.map(
                  (stop, index) =>
                    stop.location && (
                      <Marker
                        key={index}
                        position={stop.location}
                        label={(index + 1).toString()}
                      />
                    )
                )}
              </>
            )}
            {directions && <DirectionsRenderer directions={directions} />}
          </GoogleMap>
        </Box>
      </Box>

      {/* Chatbot Overlay */}
      {isChatbotOpen && (
        <Box
          sx={{
            position: "fixed",
            bottom: 20,
            right: 20,
            width: 300,
            height: 400,
            zIndex: 1000,
            boxShadow: 3,
            borderRadius: 2,
            overflow: "hidden",
          }}
        >
          {/* Implement your Chatbot component here */}
          <Typography>Chatbot placeholder</Typography>
        </Box>
      )}
    </Box>
  );
};

export default RouteOpt;
